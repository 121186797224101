.annotation {
  background: rgba(0, 0, 0, 0.2);

  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);

  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 100%;
  border: 1px solid white;
  cursor: pointer;
}
.annotation:hover {
  border-color: cyan;
  color: cyan;
}
.annotationCont {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 10px;
}
.annotationText {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
  padding: 10px;
  border-radius: 10px;
}
