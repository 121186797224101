.controllerProperties {
    width: 50%;
    box-sizing: border-box;
    float: left;
}

.controllerProperties-heading {
    width: 100%;

    border-bottom-style: solid;
    border-bottom-color: #525252;
    border-bottom-width: 1px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 5px;
}

.controllerProperties-controls {
    width: 100%;
}

.controllerProperties-controls-button {
    width: 5px;
    height: 10px;
}

.controllerProperties-controls>ul {
    width: 100%;
    list-style: none;
    padding: 5px;
}

.controllerOption {
    width: 100%;
    color: white;
    display: flex;
    align-content: baseline;
    margin: 10px 5px;
}

.controllerOption-button {
    width: 20px;
    height: 20px;
    border-radius: 100px;
}

.controllerOption-label {
    width: calc(100% - 35px);
    text-align: justify;
    font-size: 12px;
    padding-left: 7px;
    font-weight: 400;
}


.controllerOption-popover-button {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    margin: 10px;
}