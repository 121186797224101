.react-flow__pane {
  cursor: auto;
}

.handle {
  padding: 3px;
  border: 4px solid rgb(109, 143, 255) !important;
  background: white !important;
}

.react-flow__pane.dragging {
  cursor: grabbing;
}

.cursor {
  position: absolute;
  z-index: 50;
  pointer-events: none;
  user-select: none;
}
