.card_selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid grey;
}
.cards {
  display: flex;
  flex-direction: column;
  padding: 2px;
  align-items: center;
}
