.loadingScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingScreen > p {
  color: white;
  font-size: 40px;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.8);
  padding: 20px;
  margin-top: 5px;
}
