.RightClickMenu {
  position: absolute;
  min-width: 280px;
  border: 1px solid #333;
  background-color: rgba(18, 17, 23, 0.8);
  box-shadow: 0px 2px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(16px);
  padding: 0.5rem;
  border-radius: 1rem;
  z-index: 9999;
}

.RightClickMenu > ul {
  list-style: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.RightClickMenu > ul > .RightClickMenu-li-normal {
  height: 36px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  color: #eee;
  border-radius: 0.5rem;
  font-size: 12px;
}

.RightClickMenu > ul > .RightClickMenu-li-normal:hover {
  background-color: rgba(204, 197, 197, 0.205);
  color: white;
  cursor: pointer;
}

.RightClickMenu > ul > .RightClickMenu-li-disabled {
  /* background-color: pink; */
  width: 100%;
  padding: 2px 6px;
  /* text-align: center; */
  color: rgb(100, 100, 100);
  font-size: 12px;
  border-radius: 2px;
}
