body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

:root {
  --toastify-color-light: rgba(18, 17, 23, 0.9) !important;
}

.Toastify__toast {
  padding: 0.5rem !important;
  border-radius: 1rem !important;
  backdrop-filter: blur(16px) !important;
}

.Toastify__close-button {
  color: white !important;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: none;
}

[data-rsbs-overlay] {
  background-color: rgba(18, 17, 23, 0.3) !important;
  z-index: 10002 !important;
  color: white;
}

[data-rsbs-scroll] {
  flex-shrink: 0 !important;
}

[data-rsbs-header] {
  cursor: pointer !important;
  padding: 0 !important;
}

[data-rsbs-root]:after {
  background-color: rgba(18, 17, 23, 0.3) !important;
}

[data-rsbs-header]:before {
  content: none !important;
}

/* StoryBoard */
.storyboard-bg-color {
  background-color: #191d28 !important;
}

.storyboard-bg-color button {
  background: #3d3d3d;
  border: none;
  border-radius: 4px;
  margin-bottom: 4px;
}

.storyboard-bg-color button:hover {
  background: #4d4d4d;
}

.storyboard-bg-color button svg {
  fill: white;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
