.tabItem{
    padding: 10px;
  font-size: 14px;
  width: 10rem;
  text-align: left;
  display: flex !important;
  justify-content: flex-start !important;

}

.tabItem:hover,
.tabSelected {
  width: 12rem;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;

  text-align: left;
  display: flex !important;
  justify-content: flex-start !important;

   background: #80808059;
}
