.controllerPreview {
  height: 150px;
  border-top-style: solid;
  border-top-color: #525252;
  border-top-width: 1px;

  border-bottom-style: solid;
  border-bottom-color: #525252;
  border-bottom-width: 1px;
}

.interactions-heading {
  height: 20px;
  color: white;
  font-size: 14px;
  margin: 10px 5px 10px 5px;
  font-weight: 500;
  display: inline-block;
}
