.handProperties {
    width: 50%;
    box-sizing: border-box;
    float: left;
}

.handProperties-heading {
    width: 100%;

    border-bottom-style: solid;
    border-bottom-color: #525252;
    border-bottom-width: 1px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 5px;
}

.handProperties-controls {
    width: 100%;
}