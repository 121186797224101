.scene-comment {
  font-size: 12px;
  border-radius: 50%;
  border-bottom-left-radius: 1px;
  background-color: rgba(18, 17, 23, 0.6);
  box-sizing: border-box;
  padding: 5px;
  transition: all;
  border: none;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(16px);
  color: white;
}

.scene-comment-hover:hover {
  background-color: #5a5a5a;
  cursor: pointer;
  transform: scale(1.2);
  transform-origin: bottom left;
}
