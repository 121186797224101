.propField {
  width: 20%;
  margin: 2.5px;
  user-select: none;
}
.sequence_fields{
  width: 100%;
  margin-left: 30px;
  padding-left: 40px;
  padding-top: 2px;
  padding-bottom: 6px;
  border-left: 1px dashed #D1D1D1;
}
.fit-width {
  display: inline-block;
  width: fit-content;
}

.fit-width > .propField-input {
  width: 100% !important;
}

.propField > .propField-input:focus {
  width: 22%;
  height: 20px;
  background-color: black;
  color: rgb(241, 241, 241);
  font-size: 12px;
  appearance: none;
  text-align: center;
  border-radius: 5px;
  font-weight: 200;
}

.propField > .propField-input {
  width: 22%;
  min-width: 22%;
  padding: 0 5px;
  height: 20px;
  display: inline-block;
  background-color: rgb(40, 40, 40);
  color: white;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  font-weight: 400;
}
