.App {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

body {
  overflow: hidden;
}

.cursor-anchor {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.main_nav {
  background-color: rgba(37, 30, 30, 0.891);
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.projects_page_button {
  padding: 10px !important;
  font-size: 14px;
  text-align: left;
  display: flex !important;
  justify-content: flex-start !important;
  gap: 10px;
  width: 15rem !important;
}

.projects_page_button:hover,
.project_page_seclectedIndex {
  width: 15rem;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  text-align: left;
  display: flex !important;
  justify-content: flex-start !important;
  gap: 10px;
  background-color: rgba(51, 51, 51, 255);
}

.projects_page_button:hover {
  background-color: rgba(51, 51, 51, 0.531);
}

.tablist {
  display: flex !important;
  align-items: baseline !important;
}
.css-a5mhaz {
  padding: 0 !important;
}
