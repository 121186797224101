.topPanel {
  position: absolute;
  top: 15px;
  left: calc(50% - 200px);
  background-color: rgba(18, 17, 23, 0.6);
  width: 350px;
  height: 43px;
  border-radius: 30px;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(16px);
  box-sizing: border-box;
  padding: 2px 2px;
}

.top-rigth-panel {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(18, 17, 23, 0.6);
  border-radius: 30px;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(16px);
  box-sizing: border-box;
  padding: 2px 2px;
}

.addCameraButton {
  color: rgb(202, 202, 202);
  height: 100%;
  border-radius: 10px;
  min-width: 40px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  padding: 8px;
}

.addCameraButton:hover {
  background-color: rgba(18, 17, 23, 1);
  color: white;
  cursor: pointer;
}
.container {
  display: flex;
  height: 40px;
  width: 350px;
  align-items: center;
  justify-content: space-around;
}

.btn {
  height: 28px !important;
  width: 30px;
  color: white;
  background: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-active {
  background: rgba(204, 197, 197, 0.205) !important;
}

.btn_pointer {
  width: auto;
  transform: scaleX(-1);
  height: 28px;
  padding: 0;
}
.btn:hover {
  background-color: rgba(204, 197, 197, 0.205) !important;
  color: white;
}
.btn_export {
  width: auto;
  display: flex;
  align-items: center;
  height: 28px;
  justify-content: center;
}
.menuItem {
  /* flex-direction: row; */
  /* display: flex; */
  /* justify-content: flex-start; */
  /* align-items: center; */
  height: 2rem;
}

.tree-item {
  /* border-radius: 0.5rem; */
  border: 1px solid transparent;
  transition: background 0.1s;
}

.tree-item:hover {
  background: rgba(204, 197, 197, 0.205);
}

.active-tree-item {
  background: rgba(204, 197, 197, 0.105);
}

.tree-item-rename {
  /* border-radius: 0.5rem; */
  background: none;
  border: 1px solid var(--chakra-colors-blue-400);
}

.tree-item-rename:hover {
  background: transparent;
}

.highlight-tree-item {
  background: rgba(164, 161, 161, 0.05);
}

.clone {
  display: inline-block;
  pointer-events: none;
  padding: 0;
  padding-left: 10px;
  padding-top: 5px;
}

.clone .tree-item {
  --vertical-padding: 5px;
  padding-right: 24px;
  border-radius: 4px;
  box-shadow: 0px 15px 15px 0 rgba(34, 33, 81, 0.1);
}

.ghost.indicator {
  opacity: 1;
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
}

.ghost.indicator .tree-item {
  position: relative;
  padding: 0;
  height: 8px;
  border-color: #2389ff;
  background-color: #56a1f8;
}

.ghost.indicator .tree-item:before {
  position: absolute;
  left: -8px;
  top: -4px;
  display: block;
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #2389ff;
  background-color: #ffffff;
}

.ghost.indicator .tree-item > * {
  opacity: 0;
  height: 0;
}

.ghost:not(.indicator) {
  opacity: 0.5;
}

.ghost,
.clone {
  background-color: transparent !important;
}

.ghost .tree-item > * {
  box-shadow: none;
  background-color: transparent;
}
